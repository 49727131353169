import { nigeriaStates } from "../nigeriaStates";

export interface IInitialState {
  numberOfWrongGuesses: number;
  userClickedLetter: string[];
  isGameOver: boolean;
  isGameWon: boolean;
  randomNigeriaState: string;
  gameId: number;
  userId: number;
  question: string;
  accessToken: string;
  status: string[];
  winningWord: string;
}

export interface StoreContextInterface {
  numberOfWrongGuesses: number;
  userClickedLetter: string[];
  store: IInitialState;
  isGameOver: boolean;
  isGameWon: boolean;
  randomNigeriaState: string;
  gameId: number;
  userId: number;
  question: string;
  accessToken: string;
  status: string[];
  winningWord: string;
  setStore: (store: IInitialState) => void;
}

const keyboard = localStorage.getItem("keyboard");

const initialKeyboard = JSON.parse(keyboard as string);

export const initialState = {
  numberOfWrongGuesses: 0,
  userClickedLetter: initialKeyboard || [],
  isGameOver: false,
  isGameWon: false,
  randomNigeriaState:
    nigeriaStates[Math.floor(Math.random() * nigeriaStates.length)],
  gameId: 0,
  userId: 0,
  question: "Demo Question",
  accessToken: "",
  status: [],
  winningWord: "",
};
