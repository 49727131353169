import { sha256 } from "js-sha256";

const digestSecretKey: string | undefined =
  process.env.REACT_APP_DIGEST_SECRET_KEY;

export const digestSecret = (data: any): string => {
  const d = JSON.stringify(data);
  let digest: string = "";
  if (digestSecretKey) {
    digest = sha256.hmac(digestSecretKey, d);
  }
  return digest;
};
