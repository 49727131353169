import { useEffect } from "react";
import GameSetting from "./components/GameSetting";
import Guess from "./components/Guess";
import HangMan from "./components/HangMan";
import Header from "./components/Header";
import Keyboard from "./components/Keyboard";
import { useStoreContext } from "./context/storeContext";
import axiosInstance from "./utils/axios";
import { digestSecret } from "./utils/digestSecret";

const App = () => {
  const { setStore } = useStoreContext();
  const TotalNumberOfGuessesAllowed = 6;

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const gameIdInit = params.get("game_id");
  const access_token = params.get("signature");

  useEffect(() => {
    const data = {
      game_id: gameIdInit,
      time: new Date().toISOString(),
    };
    const digest = digestSecret(data);
    axiosInstance
      .post("api/game", data, {
        headers: {
          "x-signature": digest,
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        console.log(res.data.game);
        // @ts-ignore
        setStore((prevState) => ({
          ...prevState,
          gameId: res.data.game.id,
          userId: res.data.game.user_id,
          question: res.data.question,
          accessToken: access_token,
          numberOfWrongGuesses: res.data.game.wrong_guesses,
          userClickedLetter: res.data.keyboard,
          status: res.data.status,
        }));
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="mx-auto w-4/5 sm:w-10/12 ">
        <Header />
        <HangMan />
        <Guess />
        <Keyboard TotalNumberOfGuessesAllowed={TotalNumberOfGuessesAllowed} />
        {/* <GameSetting /> */}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default App;
