import { useStoreContext } from "../context/storeContext";

const Guess = () => {
  const { isGameOver, question, status, winningWord } =
    useStoreContext();

  return (
    <div className="flex justify-center">
      <div>
        <p>{question && question}</p>
        <p className="text-center my-5">
          {isGameOver ? (
            <p>{winningWord}</p>
          ) : (
            <>
              {status.map((data) => {
                return `${data}  `;
              })}
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default Guess;
